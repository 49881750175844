<template>
  <div class="main_page">
    <div
      style="
        flex: 1;
        box-shadow: 10px 10px 10px rgb(0 0 0 / 35%);
        background: #fff;
        padding: 30px;
        min-height: 100%;
        box-sizing: border-box;
      "
    >
      <div class="page_title">
        {{ language.Bankaccountinformation }}
      </div>
      <div class="addbox">
        <el-button style="width: 100px" type="primary" @click="add()"
          >+{{ language.addto }}</el-button
        >
      </div>
      <div>
        <el-radio-group v-model="radio1" style="margin-top: 10px">
          <el-radio-button label="1">{{ language.Bankaccount }}</el-radio-button>
          <el-radio-button label="2">{{ language.VirtualWallet }}</el-radio-button>
          <el-radio-button label="3">{{ language.AlipayAccount }}</el-radio-button>
        </el-radio-group>
        <!-- 银行列表 -->
        <div style="margin-top: 10px">
          <el-table
            v-if="radio1show"
            v-loading="loading"
            :data="tableData"
            border
            style="width: 100%"
            stripe
            :header-cell-style="{ background: '#fafafa' }"
          >
            <!-- 开户行 -->
            <!-- <el-table-column
              v-if="radio1 == 1"
              prop="name"
              :label="language.BANK"
              min-width="10%"
            >
            </el-table-column> -->
            <!-- 用户名 -->
            <el-table-column
              prop="customer_name"
              :label="language.UserName"
              min-width="10%"
            >
            </el-table-column>
            <!-- 币种 -->
            <el-table-column prop="currency" :label="language.Currency" min-width="10%">
            </el-table-column>
            <!-- 银行名 -->
            <!-- <el-table-column
              v-if="radio1 == 1"
              prop="bank.bank_name"
              :label="language.BankName + '/IFSC'"
              min-width="10%"
            >
              <template slot-scope="scope">
                {{
                  scope.row.currency == "INR"
                    ? scope.row.bank_code
                    : scope.row.bank.bank_name
                }}
              </template>
            </el-table-column> -->
            <!-- 开户账号/钱包地址 -->
            <el-table-column
              prop="cardid"
              v-if="radio1 == 1"
              :label="language.Accountopeningwalletaddress"
              min-width="10%"
            >
            </el-table-column>
            <!-- 开户账号/钱包地址 -->
            <el-table-column
              prop="wallet_address"
              v-if="radio1 == 2"
              :label="language.Accountopeningwalletaddress"
              min-width="10%"
            >
            </el-table-column>
            <!-- wallet_address -->
            <!-- 支付宝账号 -->
             <el-table-column
              prop="identity"
              v-if="radio1 == 3"
              :label="language.AlipayAccount"
              min-width="10%"
            >
            </el-table-column>
            <!-- identity -->

            <el-table-column fixed="right" :label="language.Operation" min-width="10%">
              <template slot-scope="scope">
                <el-button size="mini" type="danger" @click="Deletebtn(scope.row)">{{
                  language.Delete
                }}</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <div class="paging">
            <el-pagination
              v-loading="loading"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              current-page.sync="5"
              :page-sizes="[10, 15, 20, 25, 30]"
              :page-size="limit"
              layout="sizes, prev, pager, next"
              :total="pages"
            >
            </el-pagination>
          </div>
        </div>
        <!-- 添加银行卡 -->
        <el-dialog
          :title="language.AddBankCard"
          :visible.sync="addshow"
          width="800px"
          :close-on-click-modal="false"
        >
          <el-tabs
            style="margin-top: 10px"
            v-model="activeName"
            type="card"
            @tab-click="handleClick"
          >
            <el-tab-pane
              v-for="(item, index) in addlist"
              :key="index"
              :label="item.name"
              :name="item.id"
            ></el-tab-pane>
          </el-tabs>
          <div id="addbox" style="">
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-bottom: 40px;
                border: 1px solid #efefef;
                border-top: none;
              "
            >
              <div v-if="activeName == 3">
                <!-- 用户名 -->
                <div
                  style="
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                  "
                >
                  <div
                    :style="
                      languagetype == 'EN'
                        ? 'min-width: 135px;white-space: nowrap'
                        : 'min-width: 100px;white-space: nowrap'
                    "
                  >
                    {{ language.UserName }}:
                  </div>
                  <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
                  <el-input
                    style="box-sizing: border-box"
                    v-model="UserName"
                    type="text"
                    placeholder=""
                  ></el-input>
                </div>
                <div
                  style="
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                  "
                >
                  <div
                    :style="
                      languagetype == 'EN'
                        ? 'min-width: 135px;white-space: nowrap'
                        : 'min-width: 100px;white-space: nowrap'
                    "
                  >
                    {{ language.AlipayAccount }}:
                  </div>
                  <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
                  <el-input
                    style="box-sizing: border-box"
                    v-model="identity"
                    type="text"
                    placeholder=""
                  ></el-input>
                </div>
                <!-- 币种 -->
                <div
                  style="
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                  "
                >
                  <div
                    :style="
                      languagetype == 'EN'
                        ? 'min-width: 135px;white-space: nowrap'
                        : 'min-width: 100px;white-space: nowrap'
                    "
                  >
                    {{ language.Currency }}:
                  </div>
                  <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
                  <el-select v-model="Currency" :placeholder="language.Currency">
                    <el-option
                      v-for="item in Currencylist"
                      :key="item.type_name"
                      :label="item.type_name"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
                <!-- 邮箱 -->
                <div
                  style="
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                  "
                >
                  <div
                    :style="
                      languagetype == 'EN'
                        ? 'min-width: 135px;white-space: nowrap'
                        : 'min-width: 100px;white-space: nowrap'
                    "
                  >
                    {{ language.Email }}:
                  </div>
                  <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
                  <el-input
                    style="box-sizing: border-box"
                    v-model="info.linkman_email"
                    disabled
                    type="text"
                    placeholder=""
                  ></el-input>
                </div>
                
                <div style="display: flex; justify-content: center; margin-top: 20px">
                  <el-button type="primary" @click="addAlipay()">{{
                    language.determine
                  }}</el-button>
                </div>
              </div>
              <div v-if="activeName == 2">
                <!-- 用户名 -->
                <div
                  style="
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                  "
                >
                  <div
                    :style="
                      languagetype == 'EN'
                        ? 'min-width: 135px;white-space: nowrap'
                        : 'min-width: 100px;white-space: nowrap'
                    "
                  >
                    {{ language.UserName }}:
                  </div>
                  <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
                  <el-input
                    style="box-sizing: border-box"
                    v-model="UserName"
                    type="text"
                    placeholder=""
                  ></el-input>
                </div>
                <div
                  style="
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                  "
                >
                  <div
                    :style="
                      languagetype == 'EN'
                        ? 'min-width: 135px;white-space: nowrap'
                        : 'min-width: 100px;white-space: nowrap'
                    "
                  >
                    {{ language.bindingaddress }}:
                  </div>
                  <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
                  <el-input
                    style="box-sizing: border-box"
                    v-model="bindingaddress"
                    type="text"
                    placeholder=""
                  ></el-input>
                </div>
                <!-- 币种 -->
                <div
                  style="
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                  "
                >
                  <div
                    :style="
                      languagetype == 'EN'
                        ? 'min-width: 135px;white-space: nowrap'
                        : 'min-width: 100px;white-space: nowrap'
                    "
                  >
                    {{ language.Currency }}:
                  </div>
                  <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
                  <el-select v-model="Currency" :placeholder="language.Currency">
                    <el-option
                      v-for="item in Currencylist"
                      :key="item.type_name"
                      :label="item.type_name"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
                <!-- 邮箱 -->
                <div
                  style="
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                  "
                >
                  <div
                    :style="
                      languagetype == 'EN'
                        ? 'min-width: 135px;white-space: nowrap'
                        : 'min-width: 100px;white-space: nowrap'
                    "
                  >
                    {{ language.Email }}:
                  </div>
                  <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
                  <el-input
                    style="box-sizing: border-box"
                    v-model="info.linkman_email"
                    disabled
                    type="text"
                    placeholder=""
                  ></el-input>
                </div>

                <!-- 验证码 -->
                <div
                  style="
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                  "
                >
                  <div
                    :style="
                      languagetype == 'EN'
                        ? 'min-width: 135px;white-space: nowrap'
                        : 'min-width: 100px;white-space: nowrap'
                    "
                  >
                    {{ language.VerificationCode }}:
                  </div>
                  <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
                  <el-input
                    style="box-sizing: border-box"
                    v-model="VerificationCode"
                    type="text"
                    placeholder=""
                  ></el-input>
                  <!-- white-space: nowrap; -->
                  <div style="position: absolute; right: -122px">
                    <el-button style="height: 100%" v-if="time2" type="info">{{
                      time2
                    }}</el-button>
                    <el-button type="primary" v-if="!time2" @click="Send_code(2)">{{
                      language.Send_code
                    }}</el-button>
                  </div>
                </div>
                <div style="display: flex; justify-content: center; margin-top: 20px">
                  <el-button type="primary" @click="Addwalletaddress()">{{
                    language.determine
                  }}</el-button>
                </div>
              </div>
              <div v-if="activeName == 1">
                <!-- 持卡人 -->
                <div
                  style="
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                  "
                >
                  <div
                    :style="
                      languagetype == 'EN'
                        ? 'min-width: 135px;white-space: nowrap'
                        : 'min-width: 100px;white-space: nowrap'
                    "
                  >
                    {{ language.Cardholder }}:
                  </div>
                  <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
                  <el-input
                    style="box-sizing: border-box"
                    v-model="Cardholder"
                    type="text"
                    placeholder=""
                  ></el-input>
                </div>

                <!-- 银行卡号 -->
                <div
                  style="
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                  "
                >
                  <div
                    :style="
                      languagetype == 'EN'
                        ? 'min-width: 135px;white-space: nowrap'
                        : 'min-width: 100px;white-space: nowrap'
                    "
                  >
                    {{ language.Bankcardnumber }}:
                  </div>
                  <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
                  <el-input
                    style="box-sizing: border-box"
                    v-model="Bankcardnumber"
                    type="text"
                    placeholder=""
                  ></el-input>
                </div>

                <!-- 银行代码 -->
                <!-- <div
                  style="
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                  "
                >
                  <div
                    :style="
                      languagetype == 'EN'
                        ? 'min-width: 135px;white-space: nowrap'
                        : 'min-width: 100px;white-space: nowrap'
                    "
                  >
                    {{ Currency.value == "INR" ? "IFSC" : language.BankName }}:
                   
                  </div>
                 
                  <el-select
                    v-if="Currency.value != 'INR'"
                    v-model="bank"
                    value-key="code"
                    :placeholder="language.BankName"
                  >
                    <el-option
                      v-for="item in bank_list"
                      :key="item.code"
                      :label="item.bank_name"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <el-input
                    v-if="Currency.value == 'INR'"
                    style="box-sizing: border-box"
                    v-model="Bankcode"
                    type="text"
                    placeholder=""
                  ></el-input>
                </div> -->

                <!-- 币种 -->
                <div
                  style="
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                  "
                >
                  <div
                    :style="
                      languagetype == 'EN'
                        ? 'min-width: 135px;white-space: nowrap'
                        : 'min-width: 100px;white-space: nowrap'
                    "
                  >
                    {{ language.Currency }}:
                  </div>
                  <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
                  <el-select v-model="Currency" :placeholder="language.Currency">
                    <el-option
                      v-for="item in Currencylist"
                      :key="item.type_name"
                      :label="item.type_name"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>

                <!-- 手机号 -->
                <div
                  style="
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                  "
                >
                  <div
                    :style="
                      languagetype == 'EN'
                        ? 'min-width: 135px;white-space: nowrap'
                        : 'min-width: 100px;white-space: nowrap'
                    "
                  >
                    {{ language.Email }}:
                  </div>
                  <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
                  <el-input
                    style="box-sizing: border-box"
                    v-model="info.linkman_email"
                    disabled
                    type="text"
                    placeholder=""
                  ></el-input>
                </div>

                <!-- 验证码 -->
                <!-- <div
                  style="
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                  "
                >
                  <div
                    :style="
                      languagetype == 'EN'
                        ? 'min-width: 135px;white-space: nowrap'
                        : 'min-width: 100px;white-space: nowrap'
                    "
                  >
                    {{ language.VerificationCode }}:
                  </div>
                  <el-input
                    style="box-sizing: border-box"
                    v-model="VerificationCode"
                    type="text"
                    placeholder=""
                  ></el-input>
                  <div style="position: absolute; right: -122px">
                    <el-button style="height: 100%" v-if="time2" type="info">{{
                      time2
                    }}</el-button>
                    <el-button type="primary" v-if="!time2" @click="Send_code(1)">{{
                      language.Send_code
                    }}</el-button>
                  </div>
                </div> -->
                <div style="display: flex; justify-content: center; margin-top: 20px">
                  <el-button @click="addbank()" type="primary">{{
                    language.determine
                  }}</el-button>
                </div>
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../../../api/config";
import axios from "axios";
// import payment from "../../../common/json/payment.json";
import {
  bankcard,
  wallet,
  alipay,
  emssend,
  bankcardadd,
  walletadd,
  alipayadd,
  usergetPayConf,
  bankcarddelete,
  walletdelete,
  alipaydelete,
} from "../../../api/index";
export default {
  data() {
    return {
      radio1: 1,
      language: "",
      languagetype: null,
      tableData: [],
      loading: false,
      addlist: [
        { name: "", id: "1" },
        { name: "", id: "2" },
        { name: "", id: "3" },
      ],
      pages: 1,
      page: 1,
      limit: 15,
      addshow: false,
      Balancewithdrawalshow: false,
      activeName: "1",
      BANK: "",
      mobile_number: "",
      VerificationCode: "",
      Bankcardnumber: "",
      Bankcode: "",
      Cardholder: "",
      UserName: "",
      bindingaddress: "",
      identity: "",
      Currencylist: [],
      Currency: "",
      time2: "",
      sendtimeInterval: "",
      info: "",
      radio1show: true,
      Currencylist2: [],
      bank_list: "",
      bank: null,
      transferFromId: "",
      list: "",
      otherCurrencylist: [
        {
          type_name: "MYR",
          value: "MYR",
          type: 1,
        },
        {
          type_name: "CNY",
          value: "CNY",
          type: 1,
        },
        {
          type_name: "THB",
          value: "THB",
          type: 1,
        },
        {
          type_name: "USDT",
          value: "USDT",
          type: 2,
        },
        {
          type_name: "INR",
          value: "INR",
          type: 1,
        },
        {
          type_name: "WanBi",
          value: "WanBi",
          type: 2,
        },
        {
          type_name: "GoBi",
          value: "GoBi",
          type: 2,
        },
        {
          type_name: "CNY",
          value: "CNY",
          type: 3,
        },
      ],
    };
  },
  created() {
    this.usergetPayConf();
    this.info = JSON.parse(localStorage.getItem("info"));
    this.Currencylist = [...this.Currencylist, ...this.otherCurrencylist];
    this.Currencylist2 = [...this.Currencylist2, ...this.otherCurrencylist];
    this.changelanguage();
    this.init();
  },
  watch: {
    "$store.state.language"() {
      this.changelanguage();
    },
    radio1() {
      this.init();
      this.radio1show = false;
      this.$nextTick(() => {
        this.radio1show = true;
      });
    },
    activeName() {
      this.changeactiveName();
    },
    Currency() {
      this.bank = "";
      this.Bankcode = "";
      this.getbanklist();
    },
  },
  methods: {
    // 删除银行卡或虚拟钱包或支付宝
    Deletebtn(e) {
      if (this.radio1 == 1) {
        //删除银行卡
        let data = {
          id: e.id,
        };
        this.$confirm(this.language.confirmdeletion, this.language.prompt, {
          confirmButtonText: this.language.determine,
          cancelButtonText: this.language.cancel,
          type: "warning",
        })
          .then(() => {
            bankcarddelete(data, data).then((res) => {
              if (res.code == 1) {
                this.$message({
                  type: "success",
                  message: this.language.Edit_succeeded + "!",
                });
                this.init();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: this.language.Cancelleddeletion,
            });
          });
      } else if (this.radio1 == 2) {
        //删除虚拟钱包
        let data = {
          id: e.id,
        };
        this.$confirm(this.language.confirmdeletion, this.language.prompt, {
          confirmButtonText: this.language.determine,
          cancelButtonText: this.language.cancel,
          type: "warning",
        })
          .then(() => {
            walletdelete(data, data).then((res) => {
              if (res.code == 1) {
                this.$message({
                  type: "success",
                  message: this.language.Edit_succeeded + "!",
                });
                this.init();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: this.language.Cancelleddeletion,
            });
          });
      }else if (this.radio1 == 3) {
        //删除支付宝
        let data = {
          id: e.id,
        };
        this.$confirm(this.language.confirmdeletion, this.language.prompt, {
          confirmButtonText: this.language.determine,
          cancelButtonText: this.language.cancel,
          type: "warning",
        })
          .then(() => {
            alipaydelete(data, data).then((res) => {
              if (res.code == 1) {
                this.$message({
                  type: "success",
                  message: this.language.Edit_succeeded + "!",
                });
                this.init();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: this.language.Cancelleddeletion,
            });
          });
      }
    },
    // 切换添加银行卡还是虚拟钱包
    changeactiveName() {
      this.Currencylist = [];
      this.Currencylist2.map((item) => {
        if (item.type == this.activeName && this.list.includes(item.value)) {
          this.Currencylist.push(item);
        }
      });
      this.Currency = this.Currencylist[0];
    },
    // 获取银行列表
    getbanklist() {
      if (!this.Currency) {
        return;
      }
      this.bank_list = [];
      let data = {
        currency: this.Currency.value,
        page: 1,
        limit: 200,
      };
      usergetPayConf(data).then((res) => {
        let list = [];
        res.data.list.map((item) => {
          if (item.transferSwitch == 1) {
            list.push(item);
          }
        });
        if (!list[0]) {
          return;
        }
        let num = Math.floor(Math.random() * list.length) + 1 - 1;
        this.transferFromId = list[num].type;
        let data = {
          appId: "202212207389912064",
          sign: "a714915865d526e82faf309f8aaa2ce8",
          currency: this.Currency.value,
          // mchPayConfId: list[num].type,
          transferFromId: list[num].type,
          form: "0",
          time: String(Date.parse(new Date()) / 1000),
        };
        axios.post(config.h5BaseUrl + "transfer/bankQuery", data).then((res) => {
          this.bank_list = res.data.data;
        });
      });
    },
    init() {
      if (this.radio1 == 1) {
        bankcard().then((res) => {
          this.tableData = res.data.list;
        });
      } else if (this.radio1 == 2) {
        wallet().then((res) => {
          this.tableData = res.data.list;
        });
      }else if (this.radio1 == 3) {
        alipay().then((res) => {
          this.tableData = res.data.list;
        });
      }
    },
    usergetPayConf() {
      this.list = [];
      let data = {
        page: 1,
        limit: 200,
      };
      usergetPayConf(data).then((res) => {
        console.log(res.data.list);
        res.data.list.map((item) => {
          if (item.transferSwitch == 1) {
            this.list.push(item.currency);
          }
        });
        this.changeactiveName();
      });
    },
    // 发送验证码
    Send_code(e) {
      this.time2 = 60;
      let event = "";
      if (e == 1) {
        event = "bankcardAdd";
      } else if (e == 2) {
        event = "walletAdd";
      }
      let data = {
        email: this.info.linkman_email,
        event: event,
      };
      this.timeInterval();
      emssend(data).then(() => {
        // this.timeInterval();
      });
    },
    // 验证码倒计时
    timeInterval() {
      let that = this;
      this.sendtimeInterval = setInterval(() => {
        that.time2 = that.time2 - 1;
        if (that.time2 == 0) {
          clearInterval(that.sendtimeInterval);
        }
      }, 1000);
    },
    // 添加支付宝
    addAlipay() {
      if (!this.UserName) {
        this.$message({
          message: this.language.UserName + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      if (!this.identity) {
        this.$message({
          message: this.language.AlipayAccount + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      // if (!this.VerificationCode) {
      //   this.$message({
      //     message: this.language.VerificationCode + this.language.cannotbeempty,
      //     type: "warning",
      //   });
      //   return;
      // }
      let data = {
        customer_name: this.UserName,
        identity: this.identity,
        currency: this.Currency.value,
        captcha: this.VerificationCode,
      };
      alipayadd(data).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: this.language.Edit_succeeded,
            type: "success",
          });
          this.init();
          this.addshow = false;
        }
      });
    },
    // 添加虚拟钱包
    Addwalletaddress() {
      if (!this.UserName) {
        this.$message({
          message: this.language.UserName + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      if (!this.bindingaddress) {
        this.$message({
          message: this.language.bindingaddress + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      if (!this.VerificationCode) {
        this.$message({
          message: this.language.VerificationCode + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      let data = {
        customer_name: this.UserName,
        wallet_address: this.bindingaddress,
        currency: this.Currency.value,
        captcha: this.VerificationCode,
      };
      walletadd(data).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: this.language.Edit_succeeded,
            type: "success",
          });
          this.init();
          this.addshow = false;
        }
      });
    },
    // 添加银行卡
    addbank() {
      if (!this.Cardholder) {
        this.$message({
          message: this.language.Cardholder + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      if (!this.Bankcardnumber) {
        this.$message({
          message: this.language.Bankcardnumber + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      // if (!this.Bankcode && !this.bank) {
      //   this.$message({
      //     message: this.language.BankName + this.language.cannotbeempty,
      //     type: "warning",
      //   });
      //   return;
      // }
      // if (!this.VerificationCode) {
      //   this.$message({
      //     message: this.language.VerificationCode + this.language.cannotbeempty,
      //     type: "warning",
      //   });
      //   return;
      // }
      let data = {
        customer_name: this.Cardholder,
        cardid: this.Bankcardnumber,
        // bank_code: this.Bankcode,
        currency: this.Currency.value,
        captcha: this.VerificationCode,
      };
      if (this.Currency.value == "INR") {
        data.bank_code = this.Bankcode;
      } else {
        data.my_bank_id = this.bank.my_bank_id;
      }
      bankcardadd(data).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: this.language.Edit_succeeded,
            type: "success",
          });
          this.init();
          this.addshow = false;
        }
      });
    },
    add() {
      this.Cardholder = "";
      this.Bankcardnumber = "";
      this.Bankcode = "";
      // this.Currency = "";
      this.VerificationCode = "";
      this.UserName = "";
      this.bindingaddress = "";
      this.bank = "";
      this.addshow = true;
    },
    // 切换添加
    handleClick() {
      clearInterval(this.sendtimeInterval);
      this.Cardholder = "";
      this.Bankcardnumber = "";
      this.Bankcode = "";
      this.Currency = "";
      this.VerificationCode = "";
      this.UserName = "";
      this.bindingaddress = "";
    },
    // 切换每页条数
    handleSizeChange(val) {
      this.page = 1;
      this.limit = val;
      this.init();
    },
    //选择页数
    handleCurrentChange(val) {
      this.page = val;
      this.init();
    },
    // 切换语言
    changelanguage() {
      this.languagetype = this.$store.state.language;
      this.language = this.$units();
      this.addlist[0].name = this.language.AddBankCard;
      this.addlist[1].name = this.language.Addwalletaddress;
      this.addlist[2].name = this.language.AddAlipay;
    },
  },
};
</script>
<style scoped>
.main_page {
  display: flex;
  min-height: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.page_title {
  font-size: 26px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.addbox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-bottom: 1px dashed rgb(203, 203, 203);
  padding-bottom: 4px;
}
.paging {
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
}
* >>> .el-tabs__header {
  margin: 0;
}
</style>
